import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { fetchAPI, saveToClipboard } from '../../../utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ReactComponent as HypedropSmall } from '../../../icons/sites/hypedrop_small.svg';
import { socket } from '../../../socket';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { setHypedropID } from '../../../redux/profile/profileSlice';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Modal } from '@mui/material';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    bgcolor: 'var(--primary-bg)',
    border: '2px solid var(--primary-border)',
    boxShadow: 24,
    padding: '1rem',
    borderRadius: '8px'
  }

const VerifyHypedropModal = () => {

    const [isOpen, setIsOpen] = useState(false)

    const dispatch = useDispatch();

    const [verificationCode, setVerificationCode] = useState<string | undefined>(undefined);
    const [loadingVerificationCode, setLoadingVerificationCode] = useState<boolean>(true);
    const [verificationExpireDate, setVerificationExpireDate] = useState<Date | undefined>(undefined);

    const [verified, setVerified] = useState(false);
    const [verificationExpired, setVerificationExpired] = useState(false);

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);
    

    async function requestVerification() {
            
            try {
                const data: any = await fetchAPI('/user/hypedrop/verify', 'GET')
    
                if (data.success) {
                    setVerificationExpireDate(new Date(data.data.expiresAt))
                    setVerificationCode(data.data.verificationCode);
                    setVerificationExpired(false)
                    setLoadingVerificationCode(false)
                }
    
            } catch (e) {
    
            }           
    }

    useEffect(() => {

        if(isOpen) {

            socket.on('hypedropVerified', (data) => {
                if(data.verified) {
                    setVerified(true)
                    dispatch(setHypedropID({ hypedrop_id: data.hypedropId } ))
                }              
            })

            socket.on('hypedropVerificationExpired', (data) => {
                if(data.expired) {
                    setVerificationExpired(true);
                }
                
            })


            if(verificationCode === undefined) {
                setLoadingVerificationCode(true)
                requestVerification();
            }
        }

        return () => {
            socket.off('hypedropVerified')
            socket.off('hypedropVerificationExpired')
        }

    }, [isOpen, dispatch, verificationCode, verificationExpired])

  return (
    <React.Fragment>
        <Button onClick={handleOpen} disableRipple>Verify</Button>
        <Modal
            open={isOpen}
            onClose={handleClose}
        >
              <Box display="flex" flexDirection="column" alignItems="flex-start" rowGap="2rem" sx={style}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                        <Typography component="span" sx={{ width: '38px', height: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <HypedropSmall style={{ width: '15px', height: '6px', transform: 'scale(2)' }} />
                        </Typography>
                      
                      <Typography variant="h5" alignSelf="flex-start" marginLeft=".5rem">Connect Hypedrop Account</Typography>
                  </Box>

                  {verified ? (
                    <Box display="flex" alignItems="center">
                        <CheckIcon sx={{ color: 'var(--success)', marginRight: '.5rem' }}/>
                        <Typography>You have been verified!</Typography>
                    </Box>
                  ) : verificationExpired ? (
                          <Box display="flex" flexDirection="column" width="100%" alignItems="center" justifyContent="center" rowGap="1rem">
                              <Box display="flex" alignItems="center">
                                  <CloseIcon sx={{ color: 'var(--error)', marginRight: '.5rem' }} />
                                  <Typography>Your verification code expired!</Typography>
                              </Box>

                              <Button variant="text" className="link" sx={{ textTransform: 'none' }} disableRipple onClick={() => requestVerification()}>Request new Code</Button>
                          </Box>
                  ) : (
                      <Box display="flex" width="100%" flexDirection="column" rowGap="1rem">
                                  <Box display="flex" alignItems="center" justifyContent="center" marginBottom="1rem" sx={{ 'svg': { height: 'auto', width: 'auto' }}}>
                                      {verificationExpireDate && (
                                          <CountdownCircleTimer
                                              trailColor={'#261f30'}
                                              isPlaying
                                              duration={300}
                                              initialRemainingTime={verificationExpireDate ? (verificationExpireDate.getTime() - new Date().getTime()) / 1000 : 300}
                                              colors={['#534d99', '#261f30']}
                                              colorsTime={[300, 300]}
                                              size={180}

                                          >

                                              {({ remainingTime }) => remainingTime > 0 ? (
                                                    <Box display="flex" flexDirection="column" alignItems="center">
                                                        <Typography sx={{ fontSize: '1.2em' }}>{remainingTime}s</Typography>
                                                        <Typography>left</Typography>
                                                    </Box>
                                              ) : "Expired"}
                                          </CountdownCircleTimer>
                                      )}

                                  </Box>

                          <Typography textAlign="center">Your Verification Code</Typography>

                          {loadingVerificationCode ? (
                              <Typography>Generating Verification Code...</Typography>
                          ) : (
                              <Box sx={{
                                  padding: '1rem',
                                  backgroundColor: 'var(--primary-bg-light)',
                                  color: 'var(--secondary-light)',
                                  borderRadius: '.5rem',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  margin: '0 auto',
                                  width: '300px',
                                  marginBottom: '1rem',
                                  cursor: 'pointer',
                                  position: 'relative',
                                  '&:hover': {
                                      'svg': {
                                          fill: 'var(--secondary-light)'
                                      }
                                  }
                              }}
                                  onClick={verificationCode ? () => saveToClipboard(`${verificationCode}`) : () => null}
                              >
                                  <Typography>{verificationCode}</Typography>
                                  <ContentCopyIcon sx={{ position: 'absolute', right: '.5rem', fill: 'var(--text-secondary)' }} />

                              </Box>
                          )}

                          <ol>
                              <li>
                                  Go to <Typography component="span" sx={{ color: 'var(--secondary-light)' }}>Hypedrop</Typography> and open the <Typography component="span" sx={{ color: 'var(--secondary-light)' }}>chat</Typography>
                              </li>
                              <li style={{ marginTop: '.75rem' }}><Typography>Type the following message in chat: &nbsp;</Typography>
                                  <Typography
                                      onClick={verificationCode ? () => saveToClipboard(`${verificationCode}`) : () => null}
                                 
                                      sx={{
                                            color: 'var(--secondary-light)',
                                            padding: '.5rem',
                                            margin: '.5rem 0',                            
                                            backgroundColor: 'var(--primary-bg-light)',
                                            borderRadius: '.5rem',
                                            cursor: 'pointer',
                                            width: 'fit-content'
                                      }}>
                                      {loadingVerificationCode ? "Generating..." : `${verificationCode}`}
                                  </Typography>
                              </li>
                          </ol>

                          <Typography>Your verification code becomes invalid after 5 minutes</Typography>
                          <Typography sx={{ color: 'var(--error)' }}>Do not share this code with anyone</Typography>
                      </Box>
                  )}



                  <Box display="flex" width="100%">
                      <Button variant="contained" color="error" sx={{ marginLeft: 'auto' }} onClick={() => handleClose()}>Close</Button>
                  </Box>

              </Box>
          </Modal>
    </React.Fragment>
  )
}

export default VerifyHypedropModal