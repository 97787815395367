import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import { useDispatch, useSelector } from 'react-redux'
import { setStakeUsername, dispatchSetEthAddress, selectProfile, setHypedropID, setPackdrawID, setRoobetID, setTradelink } from '../../redux/profile/profileSlice'
import { ReactComponent as Steam } from '../../icons/sites/steam.svg'
import { ReactComponent as HypedropSmall } from '../../icons/sites/hypedrop_small.svg'
import { ReactComponent as EthereumIcon } from '../../icons/sites/ethereum.svg'
import { ReactComponent as PackdrawIcon } from '../../icons/sites/packdraw.svg'
import { ReactComponent as StakeIcon } from '../../icons/sites/stake.svg'
import VerifyHypedropModal from '../../components/Profile/Connections/VerifyHypedropModal'
import { apiFetch } from '../../utils'
import { createErrorToast } from '../../components/Toasts/ErrorToasts'
import { createSuccessToast } from '../../components/Toasts/SuccessToasts'
import { LoadingButton } from '@mui/lab'

const ProfileSettings = () => {

    const dispatch = useDispatch()
    const profile = useSelector(selectProfile)

    const [tradeUrl, setTradeUrl] = React.useState(profile?.trade_url || "")
    const [ethAddress, setEthAddress] = React.useState(profile?.eth_address || "")
    const [hypedropId, setHypedropId] = React.useState(profile?.hypedrop_id || "")
    const [packdrawId, setPackdrawId] = React.useState(profile?.packdrawId || "")
    const [stakeName, setStakeName] = React.useState(profile?.stakeUsername || "")

    const [isLoading, setIsLoading] = React.useState(false)


    async function updateSettings() {

      setIsLoading(true)

      try {
          const data = await apiFetch(`/user/settings`, "POST", {
            body: JSON.stringify({
              tradeUrl,
              ethAddress,
              hypedropId,
              packdrawId,
              stakeUsername: stakeName
            })
          })

          if(data) {
            dispatch(setTradelink(tradeUrl || ""))
            dispatch(dispatchSetEthAddress(ethAddress || ""))
            dispatch(setHypedropID(hypedropId || ""))
            dispatch(setPackdrawID(packdrawId || ""))
            dispatch(setStakeUsername(stakeName || ""))

            createSuccessToast("Settings updated!")
          }
        } catch(e) {
          if(e instanceof Error) {
            createErrorToast(e.message)
          }
      }

      setIsLoading(false)


  }

    if(!profile) return <></>

  return (
    <Box display="flex" flexDirection="column" gap="1rem" sx={{ mt: '1rem' }}>

        <Box display="flex" flexDirection="column" width="100%" gap=".25rem">
            <InputLabel htmlFor="tradeUrl" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Steam Trade URL</InputLabel>
            <OutlinedInput
                id="tradeUrl"
                placeholder="Steam Trade URL..."
                value={tradeUrl}
                onChange={(e) => setTradeUrl(e.target.value)}
                startAdornment={
                    <Steam
                      style={{
                        height: "24px",
                        width: "24px",
                        marginRight: "1rem",
                      }}
                    />
                  }
                endAdornment={
                    <Button
                        component="a"
                        href="https://steamcommunity.com/id/me/tradeoffers/privacy"
                        target="_blank"
                        rel="noreferrer"
                        style={{ marginLeft: "8px" }}
                    >
                        Find
                  </Button>
                }
            />
        </Box>
        <Box display="flex" flexDirection="column" width="100%" gap=".25rem">
            <InputLabel htmlFor="tradeUrl" sx={{ fontSize: '12px', fontWeight: 'bold' }}>ETH Address</InputLabel>
            <OutlinedInput
                id="ethAddress"
                placeholder="ETH Address..."
                value={ethAddress}
                onChange={(e) => setEthAddress(e.target.value)}
                startAdornment={
                    <EthereumIcon
                      style={{
                        height: "24px",
                        width: "24px",
                        marginRight: "1rem",
                      }}
                    />
                  }
            />
        </Box>

        <Box display="flex" flexDirection="column" width="100%" gap=".25rem">
            <InputLabel htmlFor="stakeUsername" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Stake Username</InputLabel>
            <OutlinedInput
                id="stakeUsername"
                placeholder="Stake Username..."
                value={stakeName}
                onChange={(e) => setStakeName(e.target.value)}
                startAdornment={
                  <StakeIcon
                    style={{
                      height: "24px",
                      width: "24px",
                      marginRight: "1rem",
                    }}
                />
                  }
            />
        </Box>

        <Box display="flex" flexDirection="column" width="100%" gap=".25rem">
            <InputLabel htmlFor="hypedropId" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Hypedrop ID</InputLabel>
            <OutlinedInput
                id="hypedropId"
                placeholder="Hypedrop Id..."
                value={hypedropId}
                onChange={(e) => setHypedropId(e.target.value)}
                disabled={profile.hypedropVerified}
                endAdornment={
                  <>
                    {!profile.hypedropVerified && (
                      <VerifyHypedropModal />
                    )}
                  </>
              }
                startAdornment={
                    <Box
                        component="span"
                        sx={{
                        width: "24px",
                        marginRight: "1rem",
                        height: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        }}
                    >
                        <HypedropSmall
                        style={{
                            width: "15px",
                            height: "6px",
                            transform: "scale(1.8)",
                        }}
                        />
                  </Box>
                  }
            />
        </Box>

        <Box display="flex" flexDirection="column" width="100%" gap=".25rem">
            <InputLabel htmlFor="packdrawId" sx={{ fontSize: '12px', fontWeight: 'bold' }}>Packdraw ID</InputLabel>
            <OutlinedInput
                id="packdrawId"
                placeholder="Packdraw Id..."
                value={packdrawId}
                onChange={(e) => setPackdrawId(e.target.value)}
                startAdornment={
                    <Box
                        component="span"
                        sx={{
                        width: "24px",
                        marginRight: "1rem",
                        height: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        }}
                    >
                        <PackdrawIcon
                          style={{
                            height: '24px',
                            width: '24px',
                          }}
                        />
                  </Box>
                  }
            />
        </Box>

        <LoadingButton
            variant="contained"
            loading={isLoading}
            sx={{ alignSelf: 'flex-start', textTransform: 'capitalize' }}
            onClick={updateSettings}
        >
            Save Settings
        </LoadingButton>

    </Box>
  )
}

export default ProfileSettings